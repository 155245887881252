import React from 'react';
import './PromoBanner.css';

const PromoBanner = () => {
    return (
        <div className="promo-banner">
           50 % Discount till midnight


                    </div>
    );
};

export default PromoBanner;
